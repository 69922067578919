@import "../../variables.scss";

.sign-up-container {
  input::placeholder {
    color: #8F8B90;
  }
}

.green-bg {
  background-color: #f9faff;
}

.pink-color-text {
  color: #ff009e;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  &.top-logo {
    top: 70px;
  }
  @media (min-width: 768px) {
    font-size: 28px;
  }
}

.top-logo {
  position: absolute;
  top: 70px;
  @media (min-width: 768px) {
    top: 50px;
  }
}

.back-btn {
  cursor: pointer;
  padding-left: 4px;
}

.max-col {
  height: 100vh;
  max-height: 100vh;
  flex: 1;
  position: relative;
  @media (min-width: 768px) {
    overflow: auto;
  }
}

.email-wrapper {
  width: 100%;
  margin-top: 50px;
  padding: 0 20px;
  @media (min-width: 577px) {
    width: 80%;
    padding: 0;
  }
}

.input-wrapper {
  width: 80%;
}

.btn-wrapper {
  width: 100%;
  padding: 20px;
  @media (min-width: 577px) {
    width: 70%;
  }
}

.images-wraper {
  position: relative;
  width: 100%;
  height: 600px;
  text-align: center;
  .img-1 {
    position: absolute;
    left: 22px;
    top: 20px;
  }
  .img-2 {
    position: absolute;
    left: 90px;
    top: 90px;
  }
  .img-3 {
    position: absolute;
    left: 170px;
    top: 144px;
  }
}

.sub-texts {
  color: #6b7085;
  width: 80%;
  text-align: center;
  font-size: 16px;
  b {
    color: #0923e6;
  }
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 600px;

  &.mobile {
    padding-top: 250px;
    padding-bottom: 300px;
  }
}

.sub-text {
  color: gray;
  font-size: 12px;
  font-style: italic;
}

.opt-step {
  padding: 3px 20px;
  font-size: 12px;
  margin: 0;
  line-height: 20px;
}

.language-header {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1;
  background-color: $bg-white;
  padding: 0.5rem 1rem;
  width: 100%;
  @media (min-width: 768px) {
    width: auto;
  }
}

.tooltip-text {
  visibility: hidden;
  width: 200px;
  background-color: #ffe6f1;
  padding: 5px;
  border-radius: 6px;
  position: fixed;
  margin-left: 20px;
  margin-top: -18px;
  z-index: 1;
}

.tooltip-container {
  position: relative;
  margin-left: 4px;
}
.tooltip-text::after {
  content: " ";
  position: absolute;
  top: 10px;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #ffe6f1 transparent transparent;
}

.tooltip-container:hover .tooltip-text {
  visibility: visible;
}

.school-logo {
  height: 254px;
  object-fit: cover;
  object-position: bottom;
}
.portal-bubble-img {
  position: absolute;
  top: -10vw;
}

.chat-container {
  position: absolute;
  top: 0;
  z-index: 1;
  left: 30%;
  font-size: 12px;
}

.chat-botom-text {
  position: absolute;
  bottom: 0px;
}

.left-chat {
  background: #0923e6;
  color: white;
  max-width: 100%;
  padding: 10px;
  word-break: break-word;
  border-radius: 15px 35px 35px 0;
  max-width: 270px;
}

.right-chat {
  background: #ff009e;
  color: white;
  max-width: 100%;
  padding: 10px;
  padding-left: 15px;
  padding-right: 5px;
  word-break: break-word;
  border-radius: 35px 15px 0px 35px;
  max-width: 270px;
  margin-left: 150px;
}

.left-no-logo {
  margin-left: 42px;
}

.chat-container-wrapper {
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ai-head-1 {
  font-size: 1rem;
  @media (min-width: 640px) {
    font-size: 1.75rem;
  }
}
.ai-head-2 {
  font-size: 0.875rem;
  color: #000273;
  @media (min-width: 640px) {
    font-size: 1.375rem;
  }
}
.ai-greatness-wrap {
  @media (max-width: 640px) {
    h3.font-24 {
      font-size: 0.875rem;
    }
  }
}
.ai-greatness-btns {
  position: relative;
  &:after {
    content: url("../../assets/New-images/flip-arrow.png");
    position: absolute;
    right: 50px;
    bottom: -30px;
  }
  li {
    &:nth-child(even) {
      text-align: right;
    }
  }
  .btn {
    border-radius: 1rem;
    border: 1px solid $clr-highlight;
    background-color: $bg-white;
    padding: 0.5rem 2rem;
    box-shadow: 0px 8px 8px 0px $clr-shadow;
    &:hover {
      color: $clr-highlight;
    }
    @media (max-width: 640px) {
      border-radius: 9px;
      font-size: 0.75rem;
      padding: 0.5rem 1rem;
    }
  }
}
.verify-email-container {
  .email-img {
    max-width: 120px;
  }
  @media (max-width: 576px) {
    .email-img {
      max-width: 80px;
    }
    .ai-head-1 {
      font-size: 1.25rem;
    }
    .font-16 {
      font-size: 0.875rem;
    }
    .btn-wrapper {
      width: 100%;
    }
  }
}

@media (max-width: 550px) {
  .chat-container {
    font-size: 10px;
    left: 5%;
    top: 20px;
  }
  .right-chat {
    margin-left: 50px;
  }

  .portal-bubble-img {
    margin-top: -10px;
  }

  .chat-botom-text {
    bottom: 10px;
  }

  .chat-container-wrapper {
    background-color: #e6edff;
    height: 350px;
  }
  .sign-up-container {
    h3.font-1 {
      font-size: 0.875rem;
    }
  }
  .mpm-laptop-img {
    img {
      max-width: 60%;
    }
  }
}

@media (max-width: 767px) {
  .email-input-wrap {
    padding-top: 2rem;
    height: 100%;
    max-height: 100%;
  }
}
@media (min-width: 992px) {
  .mw-70 {
    max-width: 70%;
  }
  .mpm-laptop-img img {
    max-width: 383px;
  }
}
